.link {
    color:#10446F;
    text-decoration: none;
    transition: 0.5s;
}
.link:hover {
    color:#f00000;
    text-decoration: underline;
}
.link_auth {
    color:#f00000;
    text-decoration: none;
    transition: 0.5s;
}
.link_auth:hover {
    color:#10446F;
    text-decoration: underline;
}
.nav-logo {height: 45px;}

.bgC::before {
    content: '';
    display: inline-block;
    background-image: linear-gradient(to right, rgb(16, 68, 111) calc(100%), transparent calc(50% + 60px));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 50px;
    left: 15%;
    transform: translateX(-50%);;
}
.bgCEn::before {
    content: '';
    display: inline-block;
    background-image: linear-gradient(to right, rgb(16, 68, 111) calc(100%), transparent calc(50% + 60px));
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 50px;
    left: -15%;
    transform: translateX(50%);
}

@media only screen and (max-width: 767px) {
    .bgC::before {
    content: '';
    left: 0%;
    transform: translateX(-70%);
}
    .bgCEn::before {
    content: '';
    left: 0%;
    transform: translateX(70%);
}

    .link {
        color:#10446F;
        text-decoration: none;
        transition: 0.5s;
    }
    .link:hover {
        color:#f00000;
        text-decoration: underline;
    }
    .nav-logo {height: 45px;}

}