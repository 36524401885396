.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 35, 113, 30%);
  z-index: 2;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(6, 35, 113, 30%);
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.PaymentPlans p {
  border: 1px solid #e00201;
  border-radius: 8px;
  padding: 8px;
}

.desc pre {
  text-wrap: wrap;
}
.icon_share {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.desc iframe {
  width: 100%;
  height: 250px;
}

.input_search {
    background-color: #FFF !important;
    width: 33%;
        /* border: none !important; */
    outline: none !important;
    padding: 20px;
    border: 1px solid #707070;
    border-radius: 6px;
}
@media only screen and (max-width: 767px) {
.input_search {
    background-color: #FFF !important;
    width: 100%;
        /* border: none !important; */
    outline: none !important;
    padding: 20px;
    border: 1px solid #707070;
    border-radius: 6px;
}
}