
body, *{
      font-family: 'Tajawal', sans-serif !important; /* الافتراضي للعربية */
    }

    /* اللغة الإنجليزية */
    body:lang(en)
    , *:lang(en) {
      font-family: 'Ubuntu', sans-serif !important;
    }

.overlayBenner {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
}
.overlayBennerar{
  position: absolute;
  top: 0;
  right: 20%;
  width: 50%;
  height: 100%;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
.overlayBenner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.overlayBennerar{
  position: absolute;
  top: 0;
  right: 20%;
  width: 100%;
  height: 100%;
  z-index: 2;
}
}
.input_search {
    background-color: #FFF !important;
    width: 33%;
        /* border: none !important; */
    outline: none !important;
    padding: 20px;
    border: 1px solid #707070;
    border-radius: 6px;
}
@media only screen and (max-width: 767px) {
.input_search {
    background-color: #FFF !important;
    width: 100%;
        /* border: none !important; */
    outline: none !important;
    padding: 20px;
    border: 1px solid #707070;
    border-radius: 6px;
}
}